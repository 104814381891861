import {Component} from "react";

export default class Home extends Component {
    render() {
        return (
            <>
             Home
                <a href={"/about"}>about</a>
            </>
        );
    }
}