import {Component} from "react";

export default class About extends Component {
    render() {
        return (
            <div>
                About
            </div>
        );
    }
}