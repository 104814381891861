import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import Home from "../pages/home";
import NotFound from "../pages/not_found";
import About from "../pages/about";
import LoginForm from "../pages/login/login_form";
import ForgotPassword from "../pages/login/forgot_password";

export default class Routing extends Component {
    render() {
        if (!this.props.state.loggedIn) {
            return <LoginForm handleLogin={this.props.handleLogin} errors={this.props.state.error}/>
        }

        return (
            <Switch>
                <Route exact path={["/home", "/"]} component={Home}/>
                <Route exact path={["/login"]} render={(props) => (<LoginForm {...props} handleLogin={this.props.handleLogin} errors={this.props.state.error}/>)}/>
                <Route exact path="/forgotPassword" component={ForgotPassword}/>
                <Route exact path={["/about"]} component={About}/>
                <Route component={NotFound}/>
            </Switch>
        )
    }
}
