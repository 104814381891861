import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import FormErrors from "./form_errors";
import {Link} from "react-router-dom";

import "../../assets/login.css";
import axios from "axios";

const API_PATH = 'https://www.firsthorn.co.uk/api/login';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.isFormValid = this.isFormValid.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
    }

    state = {
        email: '',
        password: '',
        errors: this.props.errors
    }

    isFormValid() {
        let validationErrors = [];
        if (this.state.email.length < 2) {
            validationErrors.push("Valid email is required");
        }
        if (this.state.password.length < 1) {
            validationErrors.push("Password cannot be empty")
        }

        this.setState({errors: validationErrors});
        return validationErrors.length === 0;
    }

    clearErrors() {
        this.setState({errors: []});
    }

    handleFormSubmit = e => {
        e.preventDefault();

        if (this.isFormValid()) {
            axios({
                method: 'post',
                url: API_PATH,
                headers: {'content-type': 'application/json'},
                data: this.state
            })
                .then(result => {
                    this.props.handleLogin(result.user);
                    if (result.error !== undefined) {
                        this.setState({errors: [result.error]});
                    }
                })
                .catch(error => {
                    this.setState({errors: [error.message]});
                });
        }
    };

    render() {
        return (
            <div className="members-page">
                <form action="#" className="sign-in-form" id="sign-in-form">
                    <h2 className="title">Login</h2>
                    <div className="input-field">
                        <Icon name="envelope" size="large" color="green"/>
                        <input placeholder='email address' name="email" onChange={(e) => {
                            this.setState({email: e.target.value});
                        }} data-lpignore="true" onFocus={this.clearErrors}/>
                    </div>
                    <div className="input-field">
                        <Icon name="lock" size="large" color="green"/>
                        <input placeholder='password' name="password" type="password" onChange={(e) => {
                            this.setState({password: e.target.value});
                        }} data-lpignore="true" onFocus={this.clearErrors}/>
                    </div>
                    {this.state.errors.length > 0 ?
                        <FormErrors errors={this.state.errors}/>
                        :
                        <Button className="btn" type="submit" content="Login" primary={(this.state.password.length > 0 && this.state.email.length > 0)}
                                onClick={e => this.handleFormSubmit(e)}/>
                    }

                    <Link to="/forgotPassword">forgot password</Link>
                </form>
            </div>
        )
    }
}

export default LoginForm