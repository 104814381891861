import React from 'react';
import {Link} from 'react-router-dom';

const NotFound = () => (
    <div style={{textAlign: "center", marginTop: "100px"}}>
        <span role="img" style={{fontSize: "900%"}} aria-label="Sad face">&#129322;</span>
        <p style={{color: "#a23e3e", fontSize: "400%", textAlign: "center", marginTop: "50px"}}>404 Page not found</p>
        <p>Sorry, the page requested cannot be found</p>
        <Link to="/">
            Goto Home page
        </Link>
    </div>
);

export default NotFound;