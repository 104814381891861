import React, {Component} from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import "../../assets/login.css"
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import {Dimmer, Loader} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import FormErrors from "./form_errors";

const API_PATH_FORGOT_PASSWORD = "https://www.pinetreesra.co.uk/api/login/forgot"

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.isFormValid = this.isFormValid.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
    }

    state = {
        email: '',
        errors: [],
        sent: false,
        loading: false
    }

    isFormValid() {
        let validationErrors = [];
        if (this.state.email.length < 2) {
            validationErrors.push("Valid email is required");
        }

        this.setState({errors: validationErrors});
        return validationErrors.length === 0;
    }

    clearErrors() {
        this.setState({errors: []});
    }

    handleFormSubmit = e => {
        e.preventDefault();

        if (this.isFormValid()) {
            this.setState({loading: true});
            fetch({
                method: 'post',
                url: API_PATH_FORGOT_PASSWORD,
                headers: {'content-type': 'application/json'},
                data: this.state
            })
                .then(result => {
                    this.setState({sent: true, loading: false});
                })
                .catch(error => {
                    this.setState({errors: [error.message]});
                });
        }
    };

    render() {
        return (
            <div className="forgot-password">
                {this.state.sent ?
                    <Message color="green" style={{margin: "0 20px", textAlign: "center"}}>
                        Thank you.<br/>
                        If a matching account is found an email will be sent to your email address.<br/>
                        This will contain a reset link to allow you to reset your password<br/>
                        <br/>
                        <b>Note:</b> The link is a single use token and will expire in 24 hours.<br/>
                        If it is not received within 10 minutes please check your spam folder.<br/>
                        Only three tokens will be sent in a 24h period (unless they are used)
                    </Message>
                    :
                    <div className="forms-container">
                        <form action="#" className="sign-in-form" id="forgot_password">
                            <h2 className="title">Forgot Password</h2><br/>
                            <div className="input-field">
                                <Icon name="envelope" size="large" color="green"/>
                                <input placeholder='email address' name="email" onChange={(e) => {
                                    this.setState({email: e.target.value});
                                }} data-lpignore="true" onFocus={this.clearErrors}/>
                            </div>
                            {this.state.errors.length > 0 ?
                                <FormErrors errors={this.state.errors}/>
                                :
                                <>
                                    {this.state.loading ?
                                        <Segment>
                                            <Dimmer active style={{height: "50px"}}>
                                                <Loader/>
                                            </Dimmer>
                                        </Segment>
                                        :
                                        <Button className="btn" type="submit" content="Submit" primary={(this.state.email.length > 0)}
                                                onClick={e => this.handleFormSubmit(e)}/>
                                    }
                                </>
                            }
                        </form>
                    </div>
                }

            </div>
        );
    }
}

export default ForgotPassword