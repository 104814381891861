import React, {Component} from "react";

class FormErrors extends Component {
    render() {
        return (
            <>
                {(this.props.errors !== undefined && this.props.errors !== null && this.props.errors.length > 0) &&
                <div style={{border: "1px solid red", padding: "10px", borderRadius: "3px", margin: "5px 0 10px 0", color: "red", background: "#fff0f0"}}>
                    {this.props.errors.map((err, i) =>
                        <div key={i}>{err}</div>
                    )}
                </div>
                }
            </>
        )
    }

}

export default FormErrors