import {Component} from "react";

export default class SiteFooter extends Component {
    render() {
        return (
            <div>
                <p className="footer">
                    © 2022 Tim Pocock. All rights reserved. <span className={"small-grey-text"}>(version: {this.props.version})</span>
                </p>
            </div>
        );
    }
}