import {Container, Loader} from "semantic-ui-react";
import "../src/assets/site.css";

import React, {Component} from 'react';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import SiteFooter from "./components/site_footer";
import SiteHeader from "./components/site_header";
import Routing from "./components/routing";
import {BrowserRouter as Router} from "react-router-dom";
import RouteChangeTracker from "./components/route_change_tracker";

import {Cookies, withCookies} from "react-cookie/";
import {instanceOf} from "prop-types";

// ReactGA.initialize("UA-204619646-2", {gaOptions: {cookieFlags: 'SameSite=None;Secure'}});
// ReactGA.pageview(window.location.pathname);

const isProduction = process.env.NODE_ENV === 'production';

class App extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.handleLogin = this.handleLogin.bind(this);
    }

    state = {
        loggedInUser: this.fetchCookie(),
        loggedIn: this.fetchCookie() !== null,
        isAdmin: this.fetchCookie() !== null && (this.fetchCookie().member_type === "committee" || this.fetchCookie().member_type === "admin"),
        error: []
    }

    fetchCookie() {
        let c = this.props.cookies.get("user");
        if (c !== undefined && c.length > 0) {
            c = JSON.parse(window.atob(c));
        } else {
            c = null;
        }

        return c;
    }

    handleLogin = (loginObj) => {
        console.log(loginObj);
        console.log(this.state);
        if (loginObj === undefined) {
            this.props.cookies.remove("user", {path: '/'});
            this.setState({loggedIn: false, loggedInUser: null, isAdmin: false});
            let baseUrl = window.location.protocol + "//" + window.location.host
            if (window.location.href !== baseUrl && window.location.href !== baseUrl + "/members") {
                window.location.replace(baseUrl);
            }
        } else {
            if (loginObj.error !== undefined) {
                this.setState({error: [loginObj.error], isAdmin: false});
            } else {
                this.handleCookie(loginObj);
            }
        }

        setTimeout(function () {
            this.setState({error: []});
        }.bind(this), 2000);
    }

    handleCookie = (loginObj) => {
        const {cookies} = this.props;
        this.setState({loggedInUser: loginObj});
        this.setState({loggedIn: this.state.loggedInUser.id !== undefined});
        cookies.set("user", window.btoa(JSON.stringify(loginObj)), {path: "/", secure: true, maxAge: 1800}); // setting the cookie 30 mins
    };

    approved = () => {
        return this.state.loggedIn && this.state.loggedInUser.complete === "1";
    }

    render() {
        return (
            <CacheBuster
                currentVersion={packageInfo.version}
                isEnabled={isProduction} //If false, the library is disabled.
                isVerboseMode={false} //If true, the library writes verbose logs to console.
                loadingComponent={<Loader/>} //If not pass, nothing appears at the time of new version check.
                onCacheClear={console.log}
            >
                <>
                {JSON.stringify(this.state)}
                <Router>
                    <RouteChangeTracker/>
                    <Container className={"site-content"}>
                        <SiteHeader/>
                        <Container className="middle-block">
                            <Routing
                                state={this.state}
                                approved={this.approved()}
                                handleLogin={this.handleLogin}
                            />
                        </Container>
                    </Container>
                    <SiteFooter version={packageInfo.version}/>
                </Router>
                </>
            </CacheBuster>
        );
    }
}

export default withCookies(App)
