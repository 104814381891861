import {Component} from "react";
import {Header} from "semantic-ui-react";

export default class SiteHeader extends Component {
    render() {
        return (
            <>
                <Header as={"h1"} content={"www.firsthorn.co.uk"} />
            </>
        );
    }
}